define('module/lightbox',['exports', 'TweenMax', 'jquery', 'fancybox'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.Lightbox = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Lightbox = function () {

        /**
         * Constructor.
         * Initialize Lightbox.
         * 
         * @author Kai Schoenhoff <ks@sopg.de>
         * 
         * Created: 2017-11-13
         * Updated: 
         * 
         * @returns {Lightbox}
         */
        function Lightbox() {
            _classCallCheck(this, Lightbox);

            /**
             * Selectors.
             * @type Object
             */
            this.oSel = {
                ce: {
                    image: {
                        self: '.element.image-element',
                        lightbox: '.js-lightbox'
                    }
                }
            };

            /**
             * Settings.
             * @type Object
             */
            this.oSet = {
                classes: {
                    //                ready           : 'ready'
                },
                data: {
                    index: 'data-index'
                    //                href  : 'data-href',
                    //                title : 'data-title'
                }
                //            autoPlay  : 5000
            };

            /**
             * Lightbox array.
             * @type Array
             */
            this.aLightboxes = [];

            /**
             * Last window width.
             * @type Integer
             */
            this.iLastWindowWidth = window.innerWidth;

            /**
             * Image CEs.
             * @type jQuery
             */
            this.$imageCes = $(this.oSel.ce.image.self);

            /**
             * initialize Lightboxes.
             * @type jQuery
             */
            this.initialize();
        }

        /**
         * Initializes all lightboxes.
         * 
         * @author Kai Schoenhoff <ks@sopg.de>
         * 
         * Created: 2017-11-13
         * Updated: 
         * 
         * @returns {undefined}
         */


        _createClass(Lightbox, [{
            key: 'initialize',
            value: function initialize() {
                this.initializeImageLightboxes();
            }
        }, {
            key: 'initializeImageLightboxes',
            value: function initializeImageLightboxes() {
                var self = this;
                this.$imageCes.find(self.oSel.ce.image.lightbox).each(function (iIdx) {
                    var $this = $(this);
                    self.initImageLightbox($this, iIdx);
                    self.aLightboxes.push($this);
                });

                return this;
            }
        }, {
            key: 'initImageLightbox',
            value: function initImageLightbox($lightbox, iIdx) {
                var _this = this;

                var self = this;
                $lightbox.attr(self.oSet.data.index, iIdx);
                $lightbox.fancybox({
                    margin: 15
                });
                $lightbox.addClass('ready');
                $(window).resize(function () {
                    if (0 !== window.innerWidth - _this.iLastWindowWidth) {

                        if (typeof $.fancybox !== 'undefined' && typeof $.fancybox.resize !== 'undefined' && $.isFunction($.fancybox.resize())) {
                            $.fancybox.resize();
                        }
                        _this.iLastWindowWidth = window.innerWidth;
                    }
                });

                /* Get the Fancybox API. */
                var oFancyboxApi = $lightbox.data('fancybox');

                return oFancyboxApi;
            }
        }]);

        return Lightbox;
    }();

    exports.Lightbox = Lightbox;
});

