define('application',['exports', 'module/slider', 'module/mobile.navigation', 'module/reloader', 'module/lightbox', 'TweenMax', 'TimelineMax', 'jquery'], function (exports, _slider, _mobile, _reloader, _lightbox) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.Application = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Application = function () {
        function Application(_ref) {
            var _ref$debug = _ref.debug,
                debug = _ref$debug === undefined ? false : _ref$debug;

            _classCallCheck(this, Application);

            this.debug = debug;

            this._oMobileNavigation = null;
            this.oSlider = null;
            this.oReloader = null;
            this.oLightbox = null;

            this.bDebug = debug;

            this.initialize();
        }
        /**
         * Initializes the application.
         * 
         * @author Kai Schoenhoff <ks@sopg.de>
         * 
         * Created: 2017-11-01
         * Updated: 
         * 
         * @returns {undefined}
         */


        _createClass(Application, [{
            key: 'initialize',
            value: function initialize() {
                this.initSlider();
                this.initMobileMenu();
                this.initLightbox();
                this.initReloader();
            }
        }, {
            key: 'initSlider',
            value: function initSlider() {
                this.oSlider = new _slider.Slider();

                return this;
            }
        }, {
            key: 'initLightbox',
            value: function initLightbox() {
                this.oLightbox = new _lightbox.Lightbox();

                return this;
            }
        }, {
            key: 'initMobileMenu',
            value: function initMobileMenu() {
                this._oMobileNavigation = new _mobile.MobileNavigation();

                return this;
            }
        }, {
            key: 'initReloader',
            value: function initReloader() {
                this._oReloader = new _reloader.Reloader();

                return this;
            }
        }]);

        return Application;
    }();

    exports.Application = Application;
});

