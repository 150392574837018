require.config(
{
    waitSeconds: 200,
    paths: {
        jquery      : 'vendor/jquery-1.x/jquery-1.11.1.min',
        jq_bridget  : 'vendor/jquery-bridget/jquery.bridget',
        //jq_mmenu    : 'vendor/jquery-mmenu/jquery.mmenu.min.all',
        jq_mmenu    : 'vendor/jquery-mmenu/jquery.mmenu.min',
//        jq_mm_fixed : 'vendor/jquery-mmenu/jquery.mmenu.fixedelements',
//        jq_cookie   : 'vendor/jquery-cookie/jquery.cookie.1.4.1',
        flickity    : 'vendor/flickity/flickity-2.0.5.pkgd',
        fancybox    : 'vendor/fancybox/source/jquery.fancybox',
        TweenLite   : 'vendor/greensock/src/uncompressed/TweenLite',
        TweenMax    : 'vendor/greensock/src/uncompressed/TweenMax',
        TimelineMax : 'vendor/greensock/src/minified/TimelineMax.min',
        easing      : 'vendor/greensock/src/uncompressed/easing/EasePack',
        scrollTo    : 'vendor/greensock/src/uncompressed/plugins/ScrollToPlugin',        
    },
    shim: {
        jq_bridget : {
            deps : ['jquery']
        },
        flickity : {
            deps : ['jq_bridget']
        },
//        isotope : {
//            deps : ['jq_bridget']
//        },
//        iso_packery : {
//            deps : ['isotope']
//        },
        jq_mmenu : {
            deps : ['jquery'] 
        },
//        jq_mm_fixed : {
//            deps : ['jq_mmenu'] 
//        },
//        jq_cookie : {
//            deps : ['jquery']
//        },
        fancybox: {
            deps: ['jquery']
        },
//        TweenMax: {
//            deps: ['easing']
//        },
//        TimelineMax: {
//            deps: ['easing']
//        },
        scrollTo : {
            deps : ['TweenMax']
        }
    }
});

/**
 * THIS IS THE MAIN ENTRY POINT
 */
require([
    'application', 
    'jquery', 
    'flickity',
//    'isotope',
//    'iso_packery',
    'jq_bridget',
    'jq_mmenu',
//    'jq_mm_fixed'
], 
function(app, jq, Flickity)
{
    'use strict';    
    
    /* Use jQuery Bridget to combine 
    Metafizzy Flickity functions with requireJS. */
    jq.bridget('flickity', Flickity);
    
    $(document).ready(function()
    {
        window.cortex.app = new app.Application(
        {
            debug : window.cortex.debug
        });
    });
});

define("main", function(){});

