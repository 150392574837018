define('module/slider',['exports', 'TweenMax', 'jquery', 'flickity'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.Slider = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Slider = function () {

        /**
         * Constructor.
         * Initializes all sliders.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2017-03-17
         * Updated: 2017-06-02
         * 
         * @returns {Slider}
         */
        function Slider() {
            _classCallCheck(this, Slider);

            /**
             * Selectors.
             * @type Object
             */
            this.oSel = {
                ce: {
                    gallery: {
                        self: '.content-element.gallery-element',
                        slider: '.slider',
                        slide: '.slide',
                        text: '.slider-text',
                        caption: '.slide-text'
                    },
                    stageslider: {
                        self: '.stage_slider',
                        slider: '.slider',
                        slide: '.slide'
                    }
                },
                aTag: '.a-tag'
            };

            /**
             * Settings.
             * @type Object
             */
            this.oSet = {
                classes: {
                    ready: 'ready',
                    aTag: 'a-tag',
                    measuringHeight: 'js-measuring-height'
                },
                data: {
                    index: 'data-index',
                    href: 'data-href',
                    title: 'data-title'
                },
                autoPlay: 5000
            };

            /**
             * Sliders array.
             * @type Array
             */
            this.aSliders = [];

            /**
             * Last window width.
             * @type Integer
             */
            this.iLastWindowWidth = window.innerWidth;

            /**
             * Gallery CEs.
             * @type jQuery
             */
            this.$galleryCes = $(this.oSel.ce.gallery.self);

            /**
             * Home Stage Slider.
             * @type jQuery
             */
            this.$stageSlider = $(this.oSel.ce.stageslider.self);

            /**
             * Teaser Slider CEs.
             * @type jQuery
             */

            this.initialize();
        }

        /**
         * Initializes all sliders.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2017-03-17
         * Updated: 2017-03-20
         * 
         * @returns {undefined}
         */


        _createClass(Slider, [{
            key: 'initialize',
            value: function initialize() {
                this.initializeGallerySliders().initializeStageSliders();
            }
        }, {
            key: 'initializeGallerySliders',
            value: function initializeGallerySliders() {
                var self = this;

                this.$galleryCes.find(self.oSel.ce.gallery.slider).each(function (iIdx) {
                    var $this = $(this);

                    self.initGallerySlider($this, iIdx);
                    self.aSliders.push($this);
                });

                return this;
            }
        }, {
            key: 'initializeStageSliders',
            value: function initializeStageSliders() {
                var self = this;
                this.$stageSlider.find(self.oSel.ce.stageslider.slider).each(function (iIdx) {
                    var $this = $(this);
                    self.initStageSlider($this, iIdx);
                    self.aSliders.push($this);
                });

                return this;
            }
        }, {
            key: 'initGallerySlider',
            value: function initGallerySlider($slider, iIdx) {
                var _this = this;

                var self = this;

                $slider.attr(self.oSet.data.index, iIdx);

                /* Get the slider text container. */
                var $sliderText = this.initGallerySliderText($slider);

                $(window).resize(function () {
                    _this.iLastWindowWidth = window.innerWidth;
                    _this.initGallerySliderText($slider);
                });

                $slider.flickity({
                    cellSelector: this.oSel.ce.gallery.slide,
                    cellAlign: 'left',
                    wrapAround: true,
                    contain: true,
                    imagesLoaded: true
                });

                /* Get the Flickity API. */
                var oFlickityApi = $slider.data('flickity');

                /* Change the slider text on cellSelect. */
                $slider.on('select.flickity', function () {
                    /* Get the current slide. */
                    var $currSlide = $slider.find(self.oSel.ce.gallery.slide).eq(oFlickityApi.selectedIndex);

                    TweenMax.to($sliderText, 0.25, {
                        autoAlpha: 0,
                        onComplete: function onComplete() {
                            $sliderText.html($currSlide.find(self.oSel.ce.gallery.caption).html());
                            TweenMax.to($sliderText, 0.25, {
                                autoAlpha: 1,
                                overwrite: 'all'
                            });
                        }
                    });
                });

                /* Add the "ready" class. */
                $slider.addClass(this.oSet.classes.ready);

                return oFlickityApi;
            }
        }, {
            key: 'initStageSlider',
            value: function initStageSlider($slider, iIdx) {
                var _this2 = this;

                var self = this;
                $slider.attr(self.oSet.data.index, iIdx);

                /* Get the slider text container. */
                //let $sliderText = this.initGallerySliderText($slider);

                $(window).resize(function () {
                    _this2.iLastWindowWidth = window.innerWidth;
                    //this.initStageSliderText($slider);
                });

                $slider.flickity({
                    cellSelector: this.oSel.ce.stageslider.slide,
                    cellAlign: 'left',
                    wrapAround: true,
                    contain: true,
                    imagesLoaded: true
                });

                /* Get the Flickity API. */
                var oFlickityApi = $slider.data('flickity');

                /* Add the "ready" class. */
                $slider.addClass(this.oSet.classes.ready);

                return oFlickityApi;
            }
        }, {
            key: 'initGallerySliderText',
            value: function initGallerySliderText($slider) {
                var iMaxTextHeight = 0;

                /* Iterate all slide captions to find the highest one. */
                $slider.find(this.oSel.ce.gallery.caption).each(function () {
                    var iHeight = $(this).height();

                    if (iHeight > iMaxTextHeight) {
                        iMaxTextHeight = iHeight;
                    }
                });

                var $sliderText = $slider.parent().find(this.oSel.ce.gallery.text);

                $sliderText.height(iMaxTextHeight);

                return $sliderText;
            }
        }]);

        return Slider;
    }();

    exports.Slider = Slider;
});

