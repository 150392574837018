define('module/reloader',['exports', 'TweenMax', 'scrollTo', 'jquery'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.Reloader = undefined;

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var Reloader = function () {

        /**
         * Constructor.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2017-03-28
         * Updated: 2017-05-22
         * 
         * @returns {Reloader}
         */
        function Reloader() {
            _classCallCheck(this, Reloader);

            /**
             * Selectors.
             * @type Object
             */
            this.oSel = {
                button: '.js-reloader',
                teaser: '.teaser',
                //            searchRes : '.search-result',
                container: {
                    category: '.js-reload-teasers-container'
                    //                search   : '.results-container'
                },
                context: {
                    category: '.content.category'
                    //                search   : '.content.search'
                }
            };

            /**
             * Settings.
             * @type Object
             */
            this.oSet = {
                offset: {
                    scroll: 400
                },
                context: {
                    cat: 'category'
                    //                search : 'search'
                },
                data: {
                    paged: 'data-paged',
                    objId: 'data-object-id',
                    amount: 'data-amount'
                },
                url: {
                    category: '/ajax/reloadCategoryTeaser'
                    //                search   : '/ajax/reloadSearchResults'
                }
            };

            /**
             * jQuery buttons array.
             * @type jQuery
             */
            this.$buttons = $(this.oSel.button);

            this.initialize();
        }

        /**
         * Initializes all reloadable elements.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2017-03-28
         * Updated: 2017-11-10
         * 
         * @returns {undefined}
         */


        _createClass(Reloader, [{
            key: 'initialize',
            value: function initialize() {
                var self = this;
                if (0 < this.$buttons.length) {
                    this.$buttons.each(function () {
                        var $button = $(this);
                        var sContext = self.getContext($button);

                        switch (sContext) {

                            case self.oSet.context.cat:
                                self.initTermReloader($button, sContext);
                                break;

                            //                    case self.oSet.context.search:
                            //                        self.initSearchReloader($button, sContext);
                            //                        break;
                        }
                    });
                }
            }
        }, {
            key: 'getContext',
            value: function getContext($button) {
                var sContext = '';

                /* Button's context is a category. */
                if (0 < $button.parents(this.oSel.context.category).length) {
                    sContext = this.oSet.context.cat;
                }

                //        /* Button's context is the search results page. */
                //        if (0 < $button.parents(this.oSel.context.search).length) {
                //            sContext = this.oSet.context.search;
                //        }

                return sContext;
            }
        }, {
            key: 'initTermReloader',
            value: function initTermReloader($button, sContext) {
                var self = this;
                var iAmount = $button.attr(self.oSet.data.amount);
                var $container = $button.parents(this.oSel.context[sContext]).find(this.oSel.container[sContext]);

                if (iAmount && 0 < iAmount && 0 < $container.length) {

                    $button.click(function () {
                        if ($button.data('is-idle')) {
                            return;
                        }

                        $button.data('is-idle', true);

                        var iPaged = $button.attr(self.oSet.data.paged);
                        var iObjId = $button.attr(self.oSet.data.objId);

                        if (iPaged && 0 < iPaged) {

                            $.ajax({
                                url: self.oSet.url[sContext],
                                type: 'POST',
                                dataType: 'json',
                                data: {
                                    paged: iPaged,
                                    id: iObjId
                                },
                                complete: function complete(oResponse) {
                                    var aJson = oResponse.responseJSON;

                                    if (0 < aJson.length) {

                                        var $first = null;

                                        $.each(aJson, function (iIdx) {
                                            var $this = $(this);

                                            if (0 === iIdx) {
                                                $first = $this;
                                                //console.log($first);
                                            }
                                            /* Add the article HTML to the container. */
                                            self.addTeaser($container, $this);
                                        });

                                        setTimeout(function () {
                                            $(document).trigger('teaser-added');

                                            /* Scroll to the first
                                            reloaded article. */
                                            if ($first) {
                                                self.scrollTo($first);
                                            }
                                        }, 50);

                                        /* Raise the paged value. */
                                        $button.attr(self.oSet.data.paged, parseInt(iPaged) + 1);

                                        /* Give the button free, again. */
                                        $button.data('is-idle', false);

                                        if (iAmount <= $container.find(self.oSel.teaser).length) {
                                            $container.addClass('view-all');
                                            console.log('view-all');
                                            $button.remove();
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }, {
            key: 'initSearchReloader',
            value: function initSearchReloader($button, sContext) {
                var self = this;
                var iAmount = $button.attr(self.oSet.data.amount);
                var $container = $button.parents(this.oSel.context[sContext]).find(this.oSel.container[sContext]);

                if (iAmount && 0 < iAmount && 0 < $container.length) {
                    $button.click(function () {
                        if ($button.data('is-idle')) {
                            return;
                        }

                        $button.data('is-idle', true);

                        var iPaged = $button.attr(self.oSet.data.paged);
                        var iObjId = $button.attr(self.oSet.data.objId);

                        if (iPaged && 0 < iPaged) {

                            $.ajax({
                                url: self.oSet.url[sContext],
                                type: 'POST',
                                dataType: 'json',
                                data: {
                                    paged: iPaged,
                                    id: iObjId
                                },
                                complete: function complete(oResponse) {
                                    var aJson = oResponse.responseJSON;

                                    if (0 < aJson.length) {

                                        var $first = null;

                                        $.each(aJson, function (iIdx) {
                                            var $this = $(this);

                                            if (0 === iIdx) {
                                                $first = $this;
                                            }
                                            /* Add the article HTML to the container. */
                                            self.addTeaser($container, $this);
                                        });

                                        /* Scroll to the first
                                        reloaded article. */
                                        if ($first) {
                                            self.scrollTo($first);
                                        }

                                        /* Raise the paged value. */
                                        $button.attr(self.oSet.data.paged, parseInt(iPaged) + 1);

                                        /* Give the button free, again. */
                                        $button.data('is-idle', false);

                                        if (iAmount <= $container.find(self.oSel.searchRes).length) {
                                            $button.remove();
                                            $container.addClass('view-all');
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }, {
            key: 'addTeaser',
            value: function addTeaser($container, $teaserHtml) {
                $container.append($teaserHtml);

                TweenMax.set($teaserHtml, { scale: 0 });
                TweenMax.to($teaserHtml, 0.3, { scale: 1 });
            }
        }, {
            key: 'scrollTo',
            value: function scrollTo($html) {
                TweenMax.to(window, 0.7, {
                    scrollTo: {
                        y: $html.offset().top - this.oSet.offset.scroll
                    },
                    ease: Power2.easeInOut
                });
            }
        }]);

        return Reloader;
    }();

    exports.Reloader = Reloader;
});

