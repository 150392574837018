define('module/mobile.navigation',['exports', 'jquery'], function (exports, _jquery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.MobileNavigation = undefined;

    var _jquery2 = _interopRequireDefault(_jquery);

    function _interopRequireDefault(obj) {
        return obj && obj.__esModule ? obj : {
            default: obj
        };
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var MobileNavigation = function () {

        /**
         * Constructor.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-04-22
         * Updated: 2016-12-01
         */
        function MobileNavigation() {
            _classCallCheck(this, MobileNavigation);

            /**
             * Class wide selectors.
             * 
             * @var Object
             */
            this._oSel = {
                nav: {
                    mobile: {
                        self: '#mobileNavigation',
                        toggler: '#mmToggler',
                        footer: '.weather-container.placed-mobile-menu',
                        mainPanel: '#mm-0.mm-opened, #mm-1.mm-opened'
                    }
                }
            };

            /**
             * Class wide settings.
             * 
             * @var Object
             */
            this._oSet = {
                classes: {
                    opened: 'opened',
                    mmopen: 'mm-opened',
                    //sOpened : 'mobile-search-opened',
                    ready: 'ready'
                }
            };

            /**
             * jQuery mmenu opened flac.
             * 
             * @var Boolean
             */
            this._bMmOpened = false;

            /**
             * Mobile menu toggler jQuery object.
             * 
             * @var jQuery
             */
            this._$toggler = (0, _jquery2.default)(this._oSel.nav.mobile.toggler);

            /**
             * Search object holder.
             * 
             * @var Object
             */
            //        this._oSearch = {};

            /**
             * jQuery mmenu API instance.
             * 
             * @var Object
             */
            this._oMmApi = null;

            this.initMobileMenu();
            //this.initMobileSearch();
        }

        /**
         * Initializes the mobile menu.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-04-22
         * Updated: 2016-04-25
         * 
         * @return {undefined}
         */


        _createClass(MobileNavigation, [{
            key: 'initMobileMenu',
            value: function initMobileMenu() {
                var _this = this;

                var $mm = (0, _jquery2.default)(this._oSel.nav.mobile.self);

                var $wrappedWeather = (0, _jquery2.default)('.weather-container.placed-mobile-menu');

                if (0 < $mm.length && _jquery2.default.fn.mmenu) {

                    /* Initialize $.mmenu. */
                    $mm.mmenu({
                        //                    navbar : {
                        //                        title : '<span class="mobile-title">Dresden Magazin</span><span class="mobile-menu-button mm-toggler opened"><span></span><span></span><span></span><span></span></span>',
                        //                        titleLink : 'none'
                        //                    },

                        "offCanvas": {
                            "zposition": "front",
                            "position": "right"

                        },
                        'autoHeight': true,
                        extensions: [
                            //'effect-listitems-slide',
                            //'effect-menu-zoom',
                            //'effect-panels-zoom'

                        ],
                        navbars: [{
                            position: 'bottom',
                            height: 2,
                            content: [$wrappedWeather]
                        }]
                    });

                    var $mobileNavData = (0, _jquery2.default)(this._oSel.nav.mobile.footer).wrap('<div />').parent();
                    $mm.find(this._oSel.nav.mobile.mainPanel).append($mobileNavData.html());
                    $mobileNavData.remove();

                    $mm.addClass(this._oSet.classes.ready);
                    $mobileNavData.addClass(this._oSet.classes.ready);

                    /* Get the mmenu API. */
                    this._oMmApi = $mm.data('mmenu');

                    /* Bind a opening event 
                    listener to the mmenu API. */
                    this._oMmApi.bind('opened', function () {
                        _this._$toggler.addClass(_this._oSet.classes.opened);
                        _this._bMmOpened = true;
                    });

                    /* Bind a closing event 
                    listener to the mmenu API. */
                    this._oMmApi.bind('closed', function () {
                        _this._$toggler.removeClass(_this._oSet.classes.opened);
                        _this._bMmOpened = false;
                    });

                    /* Bind a window resize listener
                    that closes the mmenu. */
                    (0, _jquery2.default)(window).resize(function () {
                        if (_this._bMmOpened && !(0, _jquery2.default)('input').is(':focus')) {
                            _this._oMmApi.close();
                        }
                    });

                    /* Bind a click handler to the
                    mobile navigation toggler. */
                    this._$toggler.bind('click', function (oEvnt) {
                        oEvnt.preventDefault();

                        if (_this._bMmOpened) {
                            _this._oMmApi.close();
                        } else {
                            /* Close the mobile 
                            search area first. */
                            //                    this.closeMobileSearch();
                            _this._oMmApi.open();
                        }

                        return false;
                    });
                }
            }
        }]);

        return MobileNavigation;
    }();

    exports.MobileNavigation = MobileNavigation;
});

